// Fake Auth Provider

const authProvider = {
    login: ({ username, password }: any) => {
        if (username !== 'ikariera' || password !== 'iaadmin1948') {
            return Promise.reject();
        }
        localStorage.setItem('username', username);
        return Promise.resolve();
    },
    logout: () => {
        localStorage.removeItem('username');
        return Promise.resolve();
    },
        checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject(),
    checkError:  (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    getIdentity: () =>
        Promise.resolve({
            id: 'admin',
            fullName: 'IKariera Admin',
        }),
    getPermissions: () => Promise.resolve(''),
};

export default authProvider;
