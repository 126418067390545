import React, { Component } from 'react';
import Header from './../components/Header';
import Timeout from './../components/Timeout';
import Video from './../components/Video';
import Programme from '../components/Contest';
import Partners from '../components/Partners';

class HomePage extends Component {
  render() {
    return (
      <div>
        <Header place="VUT fakulta Podnikatelská" date="10. Dubna"/>
        <Video />
        <Timeout />
        <Programme button={true} />
        <Partners />
      </div>
    );
  }
}

export default HomePage;
