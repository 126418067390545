import { Component } from 'react';
import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import './Header.css';
import ScrollToTop from './ScrollToTop';

interface HeaderProps {
  place: string;
  date: string;
}

class Header extends Component<HeaderProps> {
  render() {
    const { place, date } = this.props;
    return (
      <div className="fixed w-full z-20">
      <Navbar bg="light" expand="lg" variant="light">
      <Container>
        <Link to="/" className="navigation-brand">
          <div className="nav-left">
          <img src="data/images/IAESTE_LC Brno.svg" className="logo-new hidden-mobile" alt="logo iaeste brno"/>
          <div className="logo-spacer hidden-mobile"> </div>
          <img src="static/ikariera-logo1.png" className="logo" alt="logo ikariera"/>
          </div>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
          <Link to="/about" className="navigation-item">O nás</Link>
            {/* <NavDropdown className="navigation-dropdown" title="Program">
              <NavDropdown.Item><Link to="/plan" className="navigation-item">Plánek veletrhu</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/company-list" className="navigation-item">Průvodce veletrhem</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to="/contest" className="navigation-item">Veletržní soutěž</Link></NavDropdown.Item>
            </NavDropdown> */}
          {/* <Link to="/for-companies" className="navigation-item">Pro vystavovatele</Link> */}
          <Link to="/company-list" className="navigation-item">Průvodce veletrhem</Link>
          {/* <Link to="/previous-years" className="navigation-item">Minulé ročníky</Link> */}
            <Link to="/plan" className="navigation-button">Vystavovatelé</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
    );
  }
}

export default Header;
