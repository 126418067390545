import React, { Component } from 'react';
import Header from './../components/Header';
import InteractivePlan from './../components/InteractivePlan';
import Partners from './../components/Partners';

class JobfairPlanPage extends Component {
  render() {
    return (
      <div>
        <Header place="VUT fakulta Podnikatelská" date="10. Dubna"/>
        <InteractivePlan svgPath="/static/ViK_2023_regsystem2.svg" svgPathMobile="/static/ViK_2023_regsystem1.svg"/>
        <Partners/>
      </div>
    );
  }
}

export default JobfairPlanPage;
