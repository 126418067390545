import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './About.css';

class About extends Component {
  render() {
    return (
      <div className="container-column pt-24">
     
      <Container>
      <div className="promote-text"><h2>Veletrh iKariéra v Brně organizuje IAESTE LC Brno</h2></div>
      <img src="data/images/IAESTE-wide-new.jpg" className="organisation-wide" alt="iaeste" />
      <div className="container-row-start">
        <div className="info-box">
          <div className="container-icon-title">
          <img src="data/icons/info.svg" alt="Hello" />
          <div className="info-title"> Informace o organizaci </div>
          </div>
          <div className="info-list">
            <div className="info-list-points">
              <ul>
                <li>IAESTE zajišťuje odbornou praxi v řadě podniků, výzkumných ústavů, laboratoří a dalších institucí</li>
                <li>Jsme mezinárodní organizace pro výměnu studentů za účelem získání technické praxe </li>
                <li>Vznikli jsme v roce 1948</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="info-box">
          <div className="container-icon-title">
          <img src="data/icons/add.svg" alt="Hello" />
          <div className="info-title"> Co se u nás naučíš? </div>
          </div>
          <div className="info-list">
            <div className="info-list-points">
              <ul>
                <li>Práci v týmů a vůdcovské dovednosti</li>
                <li>Zlepšíš si angličtinu</li>
                <li>Osvojíš si práci s grafickými programy, marketingem a to nejen na sociálních sítích</li>
                <li>Komunikační a vyjednávací dovednosti, schopnost vést B2B obchodní jednání</li>
              </ul>
            </div>
          </div>

        </div>
        <div className="info-box">
          <div className="container-icon-title">
          <img src="data/icons/bookmark.svg" alt="Hello" />
          <div className="info-title"> Jaké zázemí ti poskytneme? </div>
          </div>
          <div className="info-list">
            <div className="info-list-points">
              <ul>
                <li>Podporující komunitu příjemných lidí</li>
                <li>Sdílenou kancelář</li>
                <li>Licence prémiových programů</li>
                <li>Servery a výpočetní techniku</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-row container-center-75">
        <div className="call-to-action">
          Napiš nám na <a href="mailto:brno@iaeste.cz?subject=Zaj%C3%ADm%C3%A1m%20se%20o%20V%C3%A1%C5%A1%20spolek">brno@iaeste.cz</a> a&nbsp;nebo se ozvi přes sociální sítě
        </div>
        <div className="container-socials">
        <a href="https://www.facebook.com/iaestebrno"><img src="data/icons/facebook.svg" className="horizontal-spacing-1 hover-opacity" alt="Hello" /></a>
        <a href="https://www.instagram.com/iaeste.brno"><img src="data/icons/instagram.svg" className="horizontal-spacing-1 hover-opacity" alt="Hello" /></a>
        </div>
      </div>
      </Container>
      </div>
    );
  }
}

export default About;
