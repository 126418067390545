import React, { useState, useEffect } from 'react';
import { useTable, Column, useFilters } from 'react-table';
import { matchSorter } from 'match-sorter';
import { CompanyJobfairInfo, stallMap } from '../stallMapData';
import './InteractivePlan.css';
import ButtonForm from './ButtonForm';
import PdfViewer from './PdfViewer';
import ScrollToTop from './ScrollToTop';
import JobfairPlanPage from '../pages/JobfairPlan';

interface PlanProps {
  svgPath: string;
  svgPathMobile: string;
}

var activeStall = 0;
const STALL_STYLE_HIGHLIGHTED = "fill: #027cdb;fill-opacity:0.5;stroke:none;stroke-width:0.782855;stroke-opacity:0.966825";
const STALL_STYLE_IDLE = "fill:white;fill-opacity:0;stroke:none;stroke-width:0;stroke-opacity:0";


type TableItem = {
 name: string;
 stall: string;
//  major: string;
}

const onMobile = window.innerWidth < 767;

// function getCompanies(toggledButtonsMajorA: Set<string>, toggledButtonsMajorB: Set<string>): Set<number> {
//   var list = new Set<number>;
//   for (var obor of toggledButtonsMajorA){
//     for (var company of stallMap.values()) {
//       console.log(toggledButtonsMajorA)

//       if(obor === "Strojní" && company.s_strojni){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Elektro" && company.s_elektro){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Informatika" && company.s_it){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Ekonomie" && company.s_ekonomie){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Chemie" && company.s_chemie){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Stavebnictví" && company.s_stavebnictvi){
//         list.add(company.stallNumber)
//       }
//       if(obor === "Ostatní" && company.s_ostatni){
//         list.add(company.stallNumber)
//       }
//     }
//   }
  
//   console.log(list)
//   return list;
// }

function getCompanies(toggledButtonsMajorA: Set<string>, toggledButtonsMajorB: Set<string>): Set<CompanyJobfairInfo> {
  var list = new Set<CompanyJobfairInfo>();

  // Iterate through each company
  for (var company of stallMap.values()) {
    let matchesObor = false;
    let matchesUvazek = false;

    // Check if the company matches any selected "obor"
    if (toggledButtonsMajorA.has("Strojní") && company.s_strojni) matchesObor = true;
    if (toggledButtonsMajorA.has("Elektro") && company.s_elektro) matchesObor = true;
    if (toggledButtonsMajorA.has("Informatika") && company.s_it) matchesObor = true;
    if (toggledButtonsMajorA.has("Ekonomie") && company.s_ekonomie) matchesObor = true;
    if (toggledButtonsMajorA.has("Chemie") && company.s_chemie) matchesObor = true;
    if (toggledButtonsMajorA.has("Stavebnictví") && company.s_stavebnictvi) matchesObor = true;
    if (toggledButtonsMajorA.has("Ostatní") && company.s_ostatni) matchesObor = true;

    // Check if the company matches any selected "uvazek"
    if (toggledButtonsMajorB.has("Plný úvazek")) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Částečný úvazek") && company.m_cast_uvazek) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Diplomka") && company.m_diplomka) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Stáž") && (company.m_internship || company.m_trainee)) matchesUvazek = true;
    if (toggledButtonsMajorB.has("Brigáda") && company.m_brigada) matchesUvazek = true;

    // If the company matches both "obor" and "uvazek", add it to the list
    if (matchesObor && matchesUvazek) {
      list.add(company);
    }
  }

  return list;
}


function handlePlanToggle() :any {
  const svgContainer = document.getElementById('mobile-jobfair-plan');
  if (svgContainer !== null) {
    svgContainer.style.display = svgContainer.style.display === 'none' ? 'block' : 'none';
  }
};

const InteractivePlan: React.FC<PlanProps> = (props) => {
  const [toggledButtonsMajorA, setToggledButtonsMajorA] = useState(new Set<string>());
  const [toggledButtonsMajorB, setToggledButtonsMajorB] = useState(new Set(["Plný úvazek", "Částečný úvazek", "Diplomka", "Stáž", "Brigáda"]));
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchWasRecent, setSearchWasRecent] = useState<boolean>(true);

  const [active, setActive] = useState(0);
  var filteredCompanies : Set<CompanyJobfairInfo>;

  var planId :string;

  if (onMobile){
    planId = "jobfair-plan-mobile";
  }
  else{
    planId = "jobfair-plan";
  }

  useEffect(() => {
  var jobfairPlan:any = document.getElementById(planId);

  const highlightStalls = () => {
    if (jobfairPlan) {
      var svgDoc = jobfairPlan.contentDocument;
      // Reset all stalls to idle style first, if necessary
      for (let i = 1; i <= 74; i++) {
        const stall = svgDoc.getElementById("rect-stall-" + i);
        stall?.setAttribute("style", STALL_STYLE_IDLE);
        stall?.addEventListener("mouseenter", function(event:any) {
          if (activeStall) {
            var cleanStall = svgDoc.getElementById("rect-stall-" + activeStall);
            // cleanStall.setAttribute("style", STALL_STYLE_IDLE);
          }
          setActive(i);
          activeStall=i;
        
          // event.currentTarget.setAttribute("style", STALL_STYLE_SELECTED);
        }, false);
      }
      filteredCompanies = getCompanies(toggledButtonsMajorA,toggledButtonsMajorB );
      // Highlight stalls based on toggledButtonsMajorA
      filteredCompanies.forEach((company) => {
        const stall = svgDoc.getElementById("rect-stall-" + company.stallNumber);
        if (stall) {
          stall.setAttribute("style", STALL_STYLE_HIGHLIGHTED);
        }
      });
    }
  };

  const companiesContainer = document.getElementById('companiesContainer');

  const showCompanyList = () => {

    const element = document.getElementById('hint');
    if (element && toggledButtonsMajorA.size != 0) {
      element.style.display = 'none';
    }

    if (searchWasRecent && toggledButtonsMajorA.size != 0){
      setSearchTerm('');
      setSearchWasRecent(false);
    }

        // Clear existing content
    while (companiesContainer!.firstChild) {
      companiesContainer!.removeChild(companiesContainer!.firstChild);
    }
    filteredCompanies = getCompanies(toggledButtonsMajorA,toggledButtonsMajorB );
  
    filteredCompanies.forEach((company) => {
  
      // Create the company row (visible part) including the stall number
      const companyRow = document.createElement('div');
      companyRow.textContent = `${company.stallNumber == 73 || company.stallNumber == 74 ? "Nepřímá účast" : "Stánek "}${company.stallNumber == 73 || company.stallNumber == 74 ? "" : company.stallNumber} | ${company.companyName}`;
      companyRow.classList.add('company-row'); // Use for styling
      companyRow.style.cursor = 'pointer';
  
      // Info container (hidden part) for additional details
      const infoContainer = document.createElement('div');
      infoContainer.classList.add('info-container'); // Use for styling
  
      // Company description
      const description = document.createElement('p');
      description.textContent = company.description;
      infoContainer.appendChild(description);
  
      // Company link (if available)
      if (company.www) {
          const link = document.createElement('a');
          link.href = company.www;
          link.textContent = 'Zjistit více';
          link.target = '_blank'; // Open in a new tab
          infoContainer.appendChild(link);
          link.style.backgroundColor = '#1b75bb'; // Background color
      }
  
      // Initially hide the info container
      infoContainer.style.display = 'none';
  
      // Toggle the visibility of the info container on click
      companyRow.addEventListener('click', () => {
          infoContainer.style.display = infoContainer.style.display === 'none' ? 'block' : 'none';
      });
  
      // Append elements to the companies container
      companiesContainer!.appendChild(companyRow);
      companiesContainer!.appendChild(infoContainer);
  });
  
  };

  const showCompanyListBySearch = () => {
    while (companiesContainer!.firstChild) {
      companiesContainer!.removeChild(companiesContainer!.firstChild);
    }

    const searchFilter = (company: CompanyJobfairInfo) => {
      return company.companyName.toLowerCase().includes(searchTerm.toLowerCase());
    };

    var filtered = Array.from(stallMap.values()).filter(searchFilter);

    if (toggledButtonsMajorA.size == 0){
      setSearchWasRecent(true);
    }

    filtered.forEach((company) => {
     
      // Create the company row (visible part) including the stall number
      const companyRow = document.createElement('div');
      companyRow.textContent = `${company.stallNumber == 73 || company.stallNumber == 74 ? "Nope" : "Stánek"} ${company.stallNumber} | ${company.companyName}`;
      companyRow.classList.add('company-row'); // Use for styling
      companyRow.style.cursor = 'pointer';
  
      // Info container (hidden part) for additional details
      const infoContainer = document.createElement('div');
      infoContainer.classList.add('info-container'); // Use for styling
  
      // Company description
      const description = document.createElement('p');
      description.textContent = company.description;
      infoContainer.appendChild(description);
  
      // Company link (if available)
      if (company.www) {
          const link = document.createElement('a');
          link.href = company.www;
          link.textContent = 'Zjistit více';
          link.target = '_blank'; // Open in a new tab
          infoContainer.appendChild(link);
          link.style.backgroundColor = '#1b75bb'; // Background color
      }
  
      // Initially hide the info container
      infoContainer.style.display = 'none';
  
      // Toggle the visibility of the info container on click
      companyRow.addEventListener('click', () => {
          infoContainer.style.display = infoContainer.style.display === 'none' ? 'block' : 'none';
      });
  
      // Append elements to the companies container
      companiesContainer!.appendChild(companyRow);
      companiesContainer!.appendChild(infoContainer);
  });
  
  };
  
  
  

  // Add event listener on load to highlight stalls
  jobfairPlan?.addEventListener("load", highlightStalls, false);
  companiesContainer?.addEventListener("load", showCompanyList, false);


  // Call highlightStalls in case the SVG is already loaded
  highlightStalls();
  showCompanyList();
  if (searchTerm != ''){
    showCompanyListBySearch();
  }

  return () => {
    jobfairPlan?.removeEventListener("load", highlightStalls, false);
    companiesContainer?.removeEventListener("load", showCompanyList, false);

  };
}, [toggledButtonsMajorA, toggledButtonsMajorB, searchTerm]); // Depend on toggledButtonsMajorA to re-run when it changes


  return (


<div className="container-column pt-24">
      <div className="mobile-version">
        <div className='mobile-planek1'>
      <object className='w-full' data={props.svgPathMobile} type="image/svg+xml" id="jobfair-plan-mobile" >
            Plánek Veletrhu pracovních příležitostí iKariéra 2023
          </object></div>
        <h4 id='hint' className='centered-hint'>👇 Vyberte obor</h4>
        <div className='mobile-buttonform'>
          <ButtonForm
            toggledButtonsMajorA={toggledButtonsMajorA}
            setToggledButtonsMajorA={setToggledButtonsMajorA}
            toggledButtonsMajorB={toggledButtonsMajorB}
            setToggledButtonsMajorB={setToggledButtonsMajorB}
          />
        </div>
        {/* <button id="toggleSvgButton"  onClick={() => handlePlanToggle()} >Ukázat na plánku</button> */}
        {/* <div id="mobile-jobfair-plan" className='mobile-planek'>
          <object type="image/svg+xml" data={props.svgPath}>
            Your browser does not support SVG
          </object>
        </div> */}
          <div className='search-mobile'>
          <input className='input-mobile'
            type="text"
            placeholder="Vyledat podle názvu"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)
            }
          />
        </div>
        <div id="companiesContainer">
        </div>
      </div>
      <div className="container-row">
        <div className="buttonform1">
        <ButtonForm
          toggledButtonsMajorA={toggledButtonsMajorA}
          setToggledButtonsMajorA={setToggledButtonsMajorA}
          toggledButtonsMajorB={toggledButtonsMajorB}
          setToggledButtonsMajorB={setToggledButtonsMajorB}
        />
        </div>
        <div className="w-full h-screen absolute pt-0 flex justify-center">
          <object className='mr-24 mt-8 z-0' data={props.svgPath} type="image/svg+xml" id="jobfair-plan">
            Plánek Veletrhu pracovních příležitostí iKariéra 2023
          </object>
        </div>
        <div className="w-full h-screen flex justify-between">
    <div className='w-1/5 h-1/2 z-10 choose-options'>
      <div className="w-1/2 h-full container-column card-background z-50">
        <div className='buttonform'>
      <ButtonForm
        toggledButtonsMajorA={toggledButtonsMajorA}
        setToggledButtonsMajorA={setToggledButtonsMajorA}
        toggledButtonsMajorB={toggledButtonsMajorB}
        setToggledButtonsMajorB={setToggledButtonsMajorB}
      />
      </div>
      </div>
    </div>
    <div className='w-1/2 h-1/2 z-10 card-company'>
      <div className=" w-1/2 h-full container-column card-background z-50">
      <div className="card-subtitle">
          <h4>{active ? (active === 73 || active === 74 ? "Nepřímá účast" : "Stánek č. " + active) : "Vyberte stánek"}</h4>
      </div>
        <div className="card-title"><a href={stallMap.get(active)?.www} target='_blank' rel="noreferrer"><h1>{stallMap.get(active)?.companyName}</h1></a></div>
        <div className="card-description">{stallMap.get(active)?.description}</div>
      </div>
    </div>

  </div>
  <div id="companiesContainer">
        </div>
      </div>
      </div>
  ); }
      <div id="companyList"></div>
 
export default InteractivePlan;
