import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Header from './../components/Header';
import Sales from './../components/Sales';
import Partners from './../components/Partners';

const ForCompaniesPage = () => {
  useEffect(() => {
    window.scrollTo(-100, 0);
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div>
      <Header place="VUT fakulta Podnikatelská" date="10. Dubna"/>
      <Sales />
      <Partners />
    </div>
  );
}

export default ForCompaniesPage;
