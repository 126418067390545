import React, { Component, useState } from 'react';
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";
import HTMLFlipBook from 'react-pageflip';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './PdfViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// function PdfViewer() {
//   const [numPages, setNumPages] = useState<undefined | number>(undefined);
//   const [pageNumber, setPageNumber] = useState<undefined | number>(undefined);

//   const HTMLPage = React.forwardRef((props: any, ref) => {
//   return (
//     /*
//       // @ts-ignore */
//     <div ref={ref}>
//     <Page pageNumber={props.number} />
//     </div>
//   );
// });

//   return (

//     <div className=''>
//     <Container className="pdf-center pt-24" fluid>

//     <div className="pdf-headline">
//     <h1>Prohlédnete si průvodce veletrhem i online!</h1>
//     </div>
//     <div className='flex justify-center'>
//     <iframe src="/static/PrV_24_SP_compressed.pdf" className='w-full md:w-1/2 h-screen' frameBorder="0"></iframe>
//     </div>
//     </Container>
//     </div>
//   );

//   function onDocumentLoadSuccess(pdf: any) {
//     setNumPages(pdf.numPages);
//     setPageNumber(1);
//   }
// }

// interface DocumentLoadSuccess {
//   numPages: number;
// }

// function PdfViewer() {
//   const [numPages, setNumPages] = useState<number | null>(null);
//   const [pageNumber, setPageNumber] = useState<number>(1);

//   const onDocumentLoadSuccess = ({ numPages }: DocumentLoadSuccess) => {
//     setNumPages(numPages);
//     setPageNumber(1);
//   };

//   const goToNextPages = (event: { preventDefault: () => void; }) => {
//     event.preventDefault(); // Prevent default button click behavior
//     setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 2, numPages ?? prevPageNumber));
//   };
  
//   const goToPreviousPages = (event: { preventDefault: () => void; }) => {
//     event.preventDefault(); // Prevent default button click behavior
//     setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 2, 1));
//   };

//   return (
//     <div className='pdf-page'>
//       <Document file="/static/PrV_24_SP_compressed.pdf" onLoadSuccess={onDocumentLoadSuccess} >
//         <div style={{ display: 'flex' }}>
//           <Page pageNumber={pageNumber} />
//           {pageNumber + 1 <= (numPages ?? 0) && <Page pageNumber={pageNumber + 1} />}
//         </div>
//       </Document>
//       <div>
//         <button onClick={goToPreviousPages} disabled={pageNumber === 1}>
//           Previous
//         </button>
//         <button onClick={goToNextPages} disabled={pageNumber + 1 >= (numPages ?? 0)}>
//           Next
//         </button>
//       </div>
//     </div>
//   );
// }

// export default PdfViewer;

interface DocumentLoadSuccess {
  numPages: number;
}

const PdfViewer = () => {
  const [numPages, setNumPages] = useState(1); // Fixed type here
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1); // Current page being viewed
  const [nextPageNumber, setNextPageNumber] = useState(1); // Next page to show after loading


  const onDocumentLoadSuccess = ({ numPages }: DocumentLoadSuccess) => {
    setNumPages(numPages);
    setPageNumber(nextPageNumber); // Update to the next page number after loading
    setIsLoading(false); // Document has loaded
  };
  

  const goToPreviousPages = () => {
    var shift = 2;
    if (window.innerWidth <= 900){
      shift = 1;
    }
    // Calculate new previous page, ensuring it doesn't go below 1
    const newPageNumber = Math.max(pageNumber - shift, 1);
    setNextPageNumber(newPageNumber); // Set this as the next page number
    setIsLoading(true); // Simulate loading
    // Simulate loading completion (replace this with actual loading logic if needed)
    setPageNumber(newPageNumber); // Update the current page number
    setIsLoading(false); // Indicate loading is complete
  };

  const pdfWidth = window.innerWidth < 900 ? window.innerWidth * 1 : window.innerWidth * 0.3;

  const goToNextPages = () => {
    var shift = 2;
    if (window.innerWidth <= 900){
      shift = 1;
    }
    // Calculate new next page, ensuring it doesn't exceed total number of pages
    const newPageNumber = Math.min(pageNumber + shift, numPages);
    setNextPageNumber(newPageNumber);
    setIsLoading(true);
    setPageNumber(newPageNumber);
    setIsLoading(false);
  };
  
  return (
   <div className='pdf-page'>
  <div className="button-row">
    <button onClick={goToPreviousPages} disabled={pageNumber <= 1} className="nav-button previous">
      &lt;
    </button>
    <div className="spacer"></div>
    <button onClick={goToNextPages} disabled={pageNumber + 1 >= numPages} className="nav-button next">
      &gt;
    </button>
  </div>
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
  <button onClick={goToPreviousPages} disabled={pageNumber <= 1} className="nav-button previous desktop">
      &lt;
    </button>
    <Document file="/static/pruvodce.pdf" onLoadSuccess={onDocumentLoadSuccess} className="document-container">
      <div className="page-container">
        <Page pageNumber={isLoading ? pageNumber : nextPageNumber} width={pdfWidth}/>
        {(isLoading ? pageNumber : nextPageNumber) + 1 <= numPages && (
          <Page pageNumber={(isLoading ? pageNumber : nextPageNumber) + 1} width={pdfWidth} className="hide-on-mobile"/>
        )}
      </div>
    </Document>
    <button onClick={goToNextPages} disabled={pageNumber + 1 >= numPages} className="nav-button next desktop">
      &gt;
    </button>
  </div>   
</div>

  );
  
};

export default PdfViewer;
