import React, { Component } from 'react';
import { Admin, Resource} from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { UserList } from "./../components/AdminUsers";
import authProvider from "./../components/AdminAuth";

const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

class AdminPage extends Component {
  render() {
    return (
      <Admin basename="/admin" dataProvider={dataProvider} authProvider={authProvider}>
        <Resource name="users" list={UserList} />
      </Admin>
    );
  }
}

export default AdminPage;
