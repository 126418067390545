import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import './Sales.css';
import { Link } from 'react-router-dom';

class Sales extends Component {
  render() {
    return (
      <div className="background-layer pt-24">
      <Container className="container-center">
        <br/>
        <br/>

      <div className="catch-text"><h2>Chcete se potkat se studenty a absolventy technicky zaměřených oborů?</h2></div>
      <div className="list-points">
      <ul>
 <li>Budete mít možnost potkat až <span className="attention">3000 studentů</span> či <span className="attention">absolventů</span> v den konání veletrhu.</li>
 <li>Veletrh se odehrává <span className="attention">přímo na půdě univerzity</span>.</li>
 <li>Vytvoříte si představu o aktuálních nárocích studentů na své budoucí zaměstnavatele.</li>
</ul>
      </div>
      <a href="https://projekty.iaeste.cz/akce/veletrh-ikariera-v-brne-2024" target="_blank" rel="noopener noreferrer" className="button-link">
      Zjistit více
</a>
      </Container>
      </div>
    );
  }
}

export default Sales;
