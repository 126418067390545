import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Header from '../components/Header';
import PdfViewer from '../components/PdfViewer';
import Partners from '../components/Partners';
import './PreviousYear.css';

class PreviousYear extends Component {
  render() {
    const eventData = [
      {
        year: "2021",
        images: ["/path/to/image1.jpg", "/path/to/image2.jpg"],
      },
      {
        year: "2020",
        images: ["/path/to/image3.jpg", "/path/to/image4.jpg"],
      },
      // Add more years as needed
    ];
    // Assuming eventData is defined here or imported
    return (
      <div className="previous-years-container">
        <Header place="VUT fakulta Podnikatelská" date="10. Dubna"/>
        <div className="content">
          {eventData.map((event) => (
            <div key={event.year} className="year-section">
            <h2>{event.year}</h2>
            <div className="images">
              {event.images.map((image, index) => (
                <img key={index} src={image} alt={`Event ${event.year}`} />
              ))}
            </div>
          </div>
          ))}
        </div>
        <Partners />
      </div>
    );
  }
}

export default PreviousYear;
