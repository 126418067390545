import React, { Component } from 'react';
import Header from './../components/Header';
import Contest from '../components/Contest';
import Partners from '../components/Partners';

class ContestPage extends Component {
  render() {
    return (
      <div className="contest-page bg-gray-900">
        <Header place="VUT fakulta Podnikatelská" date="12. Dubna"/>
        <div className='container pt-40'>
        <div className="text-center video-text-main mb-2">Vyhrát na Veletrhu je tak snadné!</div>
        <div className="text-white text-lg mb-5"> Přiď v den Veletrhu na náš IAESTE stánek a započni pátrací hru, kterou zvládneš 
        během 10 minut a nová kamera či chytré hodinky můžou být tvé.</div>
        <div className='text-center font-medium text-3xl text-white mb-5'>Losovaní probehne o 14:00</div>
        </div>
        <Contest button={false} />
        <Partners />
      </div>
    );
  }
}

export default ContestPage;
